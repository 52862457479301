import React from 'react';
import { withTheme } from 'styled-components';

const Error404 = ({ theme }) => {
  const { colorText, colorPrimary } = theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 64 80"
    >
      <path
        fill={colorText}
        d="M61,2H3C2.447,2,2,2.447,2,3v58c0,0.553,0.447,1,1,1h58c0.553,0,1-0.447,1-1V3C62,2.447,61.553,2,61,2z M19,60v-2  c0-3.859,3.141-7,7-7h12c3.859,0,7,3.141,7,7v2H19z M14,40h-2V30h2V40z M16,24h32v25H16V24z M31,17c0-0.552,0.448-1,1-1s1,0.448,1,1  s-0.448,1-1,1S31,17.552,31,17z M50,30h2v10h-2V30z M60,60H47v-2c0-2.826-1.312-5.349-3.355-7H49c0.553,0,1-0.447,1-1v-8h3  c0.553,0,1-0.447,1-1V29c0-0.553-0.447-1-1-1h-3v-5c0-0.553-0.447-1-1-1H33v-2.184c1.161-0.414,2-1.514,2-2.816c0-1.654-1.346-3-3-3  s-3,1.346-3,3c0,1.302,0.839,2.402,2,2.816V22H15c-0.553,0-1,0.447-1,1v5h-3c-0.553,0-1,0.447-1,1v12c0,0.553,0.447,1,1,1h3v8  c0,0.553,0.447,1,1,1h5.355C18.312,52.651,17,55.174,17,58v2H4V12h56V60z M60,10H4V4h56V10z"
      />
      <rect fill={colorText} x="6" y="6" width="2" height="2" />
      <rect fill={colorText} x="10" y="6" width="2" height="2" />
      <rect fill={colorText} x="14" y="6" width="2" height="2" />
      <rect fill={colorText} x="28" y="6" width="30" height="2" />
      <polygon
        fill={colorPrimary}
        points="27.293,37.707 28.707,36.293 25.914,33.5 28.707,30.707 27.293,29.293 24.5,32.086 21.707,29.293 20.293,30.707   23.086,33.5 20.293,36.293 21.707,37.707 24.5,34.914 "
      />
      <polygon
        fill={colorPrimary}
        points="36.707,37.707 39.5,34.914 42.293,37.707 43.707,36.293 40.914,33.5 43.707,30.707 42.293,29.293 39.5,32.086   36.707,29.293 35.293,30.707 38.086,33.5 35.293,36.293 "
      />
      {/* <path
        fill={colorPrimary}
        d="M38,47c0.553,0,1-0.447,1-1c0-3.859-3.141-7-7-7s-7,3.141-7,7c0,0.553,0.447,1,1,1H38z M32,41c2.415,0,4.435,1.721,4.899,4  h-9.799C27.565,42.721,29.585,41,32,41z"
      /> */}
    </svg>
  );
};

export default withTheme(Error404);
